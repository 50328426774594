.car-content {
  padding: 0.7 * $unitY 0.4 * $unitX;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: $fontSize-small;

  @media (min-width: 1024px) {
    font-size: $fontSize-normal;
  }

  &--pos-0 {
    padding: 0;
    padding-left: $unitX;
    padding-right: $unitX;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 1024px) {
      padding-right: 3 * $unitX;
    }

    p {
      @media (min-width: 1024px) {
        padding-right: 4em;
      }
    }
  }

  &--pos-1 {
    justify-content: flex-end;
  }
}


/// Added by Andi
.collage {
 
}
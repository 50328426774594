.contact-form {
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  &__input {
    font-size: $fontSize-small;
    display: block;
    width: 100%;
    height: 2rem;
    line-height: 2rem;
    border: none;
    outline: none;
    border-bottom: 1px solid $textColor-midGrey;
    margin-bottom: 4px;
    padding: 0;
    border-radius: 0;

    &::placeholder {
      color: $textColor-darkGrey;
    }

    &--invalid {
      border-bottom-color: $themeColor-Red;
    }
  }

  &__textarea {
    height: 4rem;
    font-family: inherit;
    line-height: 1.2rem;
    padding-top: 0.3rem;

    &--invalid {
      border-bottom-color: $themeColor-Red;
    }
  }

  &__submit {
    background: white;
    border: none;
    outline: none;
    padding: 0;
    line-height: 1.5rem;
    margin-top: 0.8rem;
    margin-right: 1rem;
    float: left;
    cursor: pointer;
    border-bottom: 3px solid $themeColor-Orange;

    // &:focus {
    //   border-bottom-color: $themeColor-Green;
    // }

    &:disabled {
      color: $textColor-lightGrey;
    }

    &--success {
      border-bottom-color: $themeColor-Green;
    }

    &--failed {
      border-bottom-color: $themeColor-Red;
    }
  }

  &__spinner {
    float: left;
    margin-top: 4px;
  }

  &__res-message {
    font-size: $fontSize-small;
    float: left;
    width: 100%;
    margin-top: 1em;

    &--success {
    }

    &--failed {
      color: white;
      background-color: $themeColor-Red;
      padding: 2px 9px;
    }
  }
}

.home-carousel {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.home-carousel__l {
  height: 100vh;
  width: calc(100% - 400px);

  .swiper-container {
    height: 100vh;
    width: 100%;
  }
}

.home-carousel__slide-title {

}

.home-carousel__slide-text {

}

.home-carousel__slide-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.home-carousel__right {
  width: 400px;
}

.home-carousel__tr {
  margin-right: 50px;
  height: 50vh;

  .swiper-container {
    height: 100%;
  }
}

.home-carousel__video {
  height: 25vh;
  position: relative;
}

.home-carousel__video-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: black;
}

.home-carousel__video-player {
  position: absolute;
  bottom: 0;
  right: 100%;
  height: 25vh;
  width: 44.44vh;
  z-index: 1;

  transition: height 0.3s ease, width 0.3s ease;

  iframe {
    width: 100%;
    height: 100%;

    transition: height 0.3s ease, width 0.3 ease;
  }

  &>div {
    width: 100%;
    height: 100%;
  }

  &.active {
    height: 50vh;
    width: 88.9vh;

    // iframe {
    //   height: 50vh;
    // }
  }
}

.home-carousel__br {
  height: 25vh;

  .swiper-container {
    height: 100%;
  }
}

.home-carousel__nav {
  position: fixed;
  bottom: 0;
  left: 100px;
  z-index: 100;
}

.carousel-nav {
  position: absolute;
  right: $unitX;
  z-index: 6;

  &__pg {
    display: none;
  }

  &__btn {
    padding: 0;

    &:focus {
      color: $themeColor-Green;
    }
  }

  &__btn-content {
    outline: none;
  }

  @media (max-width : 1023px) {
    top: calc(#{50vh - $unitY} - 30px);
  }

  @media (min-width: 1024px) {
    bottom: $unitY;
    left: $unitX;
    width: $carouselNavWidth;
    font-size: $fontSize-small;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    &__pg {
      display: block;
      margin-right: $unitX;
    }

    &__pg-num {

      &--current {
        font-weight: bold;
        margin-right: 2px;
      }

      &--total {
        margin-left: 2px;
      }
    }

    &__btns {
      display: flex;
      margin-bottom: -10px;
    }

    &__btn {
      color: #fff;
    }

    &__play, &__pause {
      margin: 0 4px;
    }
  }
}

$titleHeight: 80px;

.legal {
  background-color: $themeColor-DarkOverlay;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  padding: calc(#{$siteMarginY} + 40px) $siteMarginX $siteMarginY;
  color: white;
  overflow-y: auto;
}

.legal__section {
  height: 100%;
  max-width: 50em;
  margin: 0 auto;
  position: relative;
}

.legal__title {
  font-size: 32px;
  margin-bottom: 1rem;
}

.legal__content {
  height: calc(100% - #{$titleHeight});
}

.legal__content-inner {
  max-width: 60em;

  @include body-copy;
  @include dont-break-out();

  @media (max-width: 1023px) {
    padding-bottom: $unitY;
  }
  @media (min-width: 1024px) {
    padding-right: 3em;
  }
}

.legal__close-icon {
  height: 28px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

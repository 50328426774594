.about {
  &__title {
    @include fluid-type($min-vw, $max-vw, 28px, 36px);
    margin-bottom: 1rem;
  }

  &__text {
    @include body-copy();
  }
}

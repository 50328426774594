.car-content {
  &__title {
    margin-bottom: 0.3em;

    &--0 {
      font-size: 26px;
    }
  }

  &__read-more-link {
    margin-top: 1rem;
    @include read-more-link;
  }

  &__carousel-link--cover {
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  @media (max-width: $mob-max-vw) {
    &__rollover-text {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    p {
      font-size: $fontSize-small;
      line-height: $lineHeight-wide;
    }

    &__title {
      margin-bottom: 0.3em;

      &--0 {
        @include fluid-type($min-vw, $max-vw, 54px, 72px);
      }

      &--1 {
        @include fluid-type($min-vw, $max-vw, 26px, 57px);
      }

      &--2 {
        @include fluid-type($min-vw, $max-vw, 24px, 47px);
      }

      &--3 {
        @include fluid-type($min-vw, $max-vw, 26px, 50px);
      }
    }

    &__sup {
      display: flex;
      align-items: center;
      height: 32px;
    }

    &__sup-icon {
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }

    &__sup-title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
    }

    &--page-product {
      .car-content__title {
        &--0 {
          @include fluid-type($min-vw, $max-vw, 40px, 62px);
        }
        margin-bottom: 0.2em;
      }
    }
  }

  .rollover {
    height: auto;
    transition: opacity 0.3s ease, height 0.3s ease-out;
    opacity: 0;
    overflow: hidden;
    width: 100%;

    &--hidden {
      height: 0;
    }

    &--visible {
      opacity: 1;
    }
  }
}

.background-image {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #333;
}

.background-image__image,
.background-image__dummy,
.background-image__thumbnail {
  position: absolute;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: transform 0.7s ease, opacity 0.6s ease-out;
  backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
}

.background-image__thumbnail {
  filter: blur(20px);
}

.background-image__image,
.background-image__dummy {
  opacity: 0;
}

.background-image__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-image__background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #333;
}

.background-image__filter {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(transparent, black);
  opacity: 0;
  transition: opacity 0.7s ease;
}

.background-image__content {
  position: relative;
  height: 100%;
  width: 100%;
}

.background-image--zoomed .background-image__image {
  transform: scale(1.2);
}

.background-image__filter--active {
  opacity: 0.5;
}
.background-image--loaded {
  .background-image__background {
    display: none;
  }

  .background-image__thumbnail {
    opacity: 0;
  }

  .background-image__dummy {
    display: none;
  }

  .background-image__image {
    opacity: 1;
  }
}

.search-result {
  min-height: 187px;
  padding: 1.4em 1.7em;
  background-color: transparent;

  &__link {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.search-result__date {
}

.search-result__link {
  &:focus {
    outline: none;
  }
}

.search-result__title {
  margin: 0.5em 0 0;
}

.about-carousel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $themeColor-LightGrey;

  &__slide {
    width: 100vw - $rightColWidth;
    height: 100vh;
  }
}

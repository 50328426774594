.application-link {
  height: 25px;
  
  &>a {
    vertical-align: super;
  }
}

.application-link__icon {
  width: 32px;
  height: 25px;
}

$footerIconHeight: 22px;

.footer {
  ///position: fixed;
  ///padding: 0 $unitX $unitY 0;
  ///bottom: 0;
  ///right: 0;
  z-index: 20;


  //// Added by Andi - 27/11/2019

  left: 0;
  right: 0;
  padding-left: 8.33333vw;
  padding-right: 8.33333vw;
  height: calc(4em + 40px);
  z-index: 100;
  ///display: flex;
  justify-content: space-between;
  align-items: center;

  background: rgba(0,0,0,1);

  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;

  /// display none on mobile to be like the main website
  display: none;

  @media screen and (min-width:1024px){
    display: flex;
    height: 10vh;
    padding-top: 0;
    padding-bottom: 0;
  }

}
.footer--sticky {
  bottom: 0;
  right: 0;
  padding: 0 $unitX $unitY;
  color: black;
  width: 100%;
  display: flex;
 ///position: absolute;
 position: relative;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 0 10px;
}
.footer--light-text {
  color: white;
}
.footer__link {
  
  display: block;
  font-size: $fontSize-small;
  line-height: $footerIconHeight;
  position: relative;
  white-space: nowrap;
  margin-bottom: 10px;

  @media screen and (min-width:1024px){
    display: inline;
    margin-bottom: 0;
  }
  

  &:after {
    @include underline($themeColor-Red);
  }

  &:focus {
    outline: none;
  }

  &--active:after,
  &:hover:after,
  &:focus:after {
    opacity: 1;
  }

  &--social {

    display: inline-block;
    height: $footerIconHeight;
    margin-top: 20px;

    @media screen and (min-width:1024px){
      display: inline;
      margin-top: 0;
      margin-bottom: 0;
    }

    svg {
      height: 100%;
    }
  }
}

.footer__menu {
  
  display: block;
  margin: 0 auto;
  text-align: center;

  @media screen and (min-width:1024px){
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    ////align-self: flex-end;
    height: $footerIconHeight;
    margin: 0;
    text-align: left;
  }

  width: $footerMenuWidth;
  
}

.footer__menu-shadow-wrapper {
  position: fixed;
  width: $rightColWidth;
  right: 0;
  overflow: hidden;
  bottom: calc(#{$unitY} - 10px);
  height: 60px;
}

.footer__menu--shadow {
  position: absolute;
  width: $footerMenuWidth;
  color: black;
  right: $unitX;
  bottom: 10px;
}

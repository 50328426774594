.contact {
  p {
    color: black;
  }
  &__content-wrapper {
    display: flex;
  }

  &__map {
    flex-basis: 0;
    flex-grow: 1;
    height: 100vh;
    position: relative;
    background-color: $themeColor-LightGrey;
  }

  &__info {
    width: $rightColWidth;
    margin: $siteMarginY 0;
    padding: 0 0.5rem 0 $textPaddingX;
    height: calc(100vh - #{$siteMarginY * 2});

    h2 {
      margin-bottom: 1rem;
    }
  }

  &__address {
    margin-bottom: 1.8em;
  }

  &__contact-form {
    margin-bottom: 1.8em;
  }

  &__jobs {
    margin-bottom: 1.8em;
  }
}

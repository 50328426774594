$searchBarHeight: 48px;

.news {
  display: flex;
}

.news__search {
  width: $rightColWidth;
  padding: $unitY 0.5rem 0 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.news__cover-image {
  flex-basis: 0;
  flex-grow: 1;
  height: 100vh;
  position: relative;
  @include background-image-filter;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(6.4em + 40px);
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 0%,
      transparent 100%
    );
  }
}

.news__cover-text {
  color: white;
  padding: $siteMarginY $siteMarginX;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-end;
}

.news__cover-date {
}

.news__cover-title {
  @include fluid-type($min-vw, $max-vw, 36px, 50px);
  margin-bottom: 0;
}

.news__cover-read-more {
  @include read-more-link;
}

.news__search-bar {
  display: flex;
  flex-direction: column;
  padding-bottom: 3em;
  padding-left: 4em;
  padding-right: $scrollPadding + $scrollBarWidth;
  height: $searchBarHeight;
  position: relative;
}

.news__spinner {
  position: absolute;
  left: 7px;
  bottom: 9px;
}

.news__search-label {
  position: absolute;
  height: 1.9em;
  width: 1.5em;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80"><path  fill="#{$textColor-darkGrey}" d="M56 51.3c3.7-4.7 5.9-10.6 5.9-17.1 0-15.3-12.4-27.7-27.7-27.7S6.5 18.9 6.5 34.2s12.4 27.7 27.7 27.7c6.2 0 12-2.1 16.6-5.6l18.9 18.9.9-.9 3.6-3.6.7-.7L56 51.3zM13.4 34.2c0-11.5 9.3-20.8 20.8-20.8S55 22.7 55 34.2 45.7 55 34.2 55s-20.8-9.3-20.8-20.8z"%3E%3C/path%3E%3C/svg%3E');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.news__search-input {
  border: none;
  border-bottom: 1px solid $textColor-midGrey;
  line-height: 2em;
  outline: none;
  padding-left: 2em;
  font-size:

  &::placeholder {
    color: $textColor-darkGrey;
  }
}

.news__search-results {
  // display: flex;
  // flex-direction: column;
  height: calc(#{100vh - $siteMarginY} - #{$searchBarHeight});
  flex-grow: 1;
  color: white;
  // overflow-y: scroll;
  // padding-right: $scrollPadding;
  position: relative;

  &-wrap {
    min-height: calc(#{100vh - $siteMarginY} - #{$searchBarHeight});
    display: flex;
    flex-direction: column;

    @include background-image-filter;
    & .background-image {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      &__wrapper {
        flex-grow: 1;
      }
    }
  }
}

.clients {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.clients__group {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $siteMarginY $siteMarginX;
}

.clients__group-gallery {
  display: flex;
}

.clients__client {
  flex: 1;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 32%;
}

.clients__client-logo {
  align-self: center;
  width: 70%;
}

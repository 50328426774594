.about-page__content-wrapper {
  display: flex;
}

.about-page__about {
  width: $rightColWidth;
  margin: $siteMarginY 0;
  padding: 0 0.5rem 0 $textPaddingX;
  height: calc(100vh - #{$siteMarginY * 2});
}

.about-page__clients {
  flex-basis: 0;
  flex-grow: 1;
  height: 100vh;
  width: calc(100vw - #{$rightColWidth});
  position: relative;
}

.content-carousel {
  height: 100%;

  &__slide {
    // TODO: make this more robust
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    overflow: hidden;

    &--active {
      height: 100%;
      width: 100%;
      position: static;
    }
  }
}

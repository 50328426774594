.youtube-player {
  height: 100%;
  width: 100%;
  position: relative;

  &--featured {
    // width: 200%;
  }
}

.youtube-player__link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: black;
  cursor: pointer;
}

.youtube-player__play-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3px;
}

.youtube-player__cover-image {
  z-index: 14;
}
.youtube-player__cover-image--active {
  z-index: 0;
}
.youtube-player__cover-image--no-cover {
  background: #282828;
}

.youtube-player__link-text {
  &:after {
    content: attr(data-link-text);
    position: absolute;
    border-bottom: 3px solid $themeColor-Red;
    display: table-cell;
    height: 3px;
    overflow: hidden;
  }
}

.youtube-player__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  z-index: 12;

  &--active {
    display: block;
  }
}

.youtube-player__video {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  right: 0;
  z-index: 13;
  overflow: hidden;

  transform-origin: bottom right;
  transition: transform 0.3s ease;

  iframe {
    width: 100%;
    height: 100%;
    width: calc(100% + 1px);
  }

  & > div {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1024px) {
    &--active {
      transform: scale(2);
    }

    &--featured {
      right: 100%;
    }
  }
}

.pagewrap {
	
	///position: fixed;

  position: absolute;
  overflow-y: scroll;
	
	@media screen and (min-width: 1024px){
		position: static;
    overflow-y: hidden;
	}

}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh - (20vh);
  position: relative;
  overflow: hidden;
}
.layout--overlay-open {
  .footer {
    color: white;
    position: fixed;
  }
}
